const domLoad = () => { };
const windowLoad = () => {
  const svg = document.querySelector('#apocalypseme-svg');
  const link = document.querySelector('#apocalypseme-link');

  function handleMouseEnter() {
    link.classList.add('hovered');
  }

  function handleMouseLeave() {
    link.classList.remove('hovered');
  }

  svg.addEventListener('mouseenter', () => {
    handleMouseEnter();
  }, false);
  svg.addEventListener('mouseleave', () => {
    handleMouseLeave();
  }, false);

  // remove loading screen
  document.querySelector('.apocalypseme-loading').classList.remove('apocalypseme-loading');
};

// The DOMContentLoaded event fires when parsing of the current page is complete. DOMContentLoaded is a great event to use to hookup UI functionality to complex web pages.
document.addEventListener('DOMContentLoaded', domLoad, false);

// The load event fires when all files have finished loading from all resources, including ads and images.
window.addEventListener('load', windowLoad, false);
